import React from "react"

function Page(props) {
    return (
        <div className="wrapper padding">
            <section className={'section section-default-page ' + props.pageClass}>
                <div className="container">
                    <div className="col12">
                        <h1>{props.pageTitle}</h1>
                    </div>

                    <div className="clear"></div>

                    <div className="col12">
                        <div className="text-block">
                            {props.children}
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
            </section>
        </div>
    );
}

export default Page
