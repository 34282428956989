import React from "react"
import Layout from "../components/layout"
import Page from "../components/page"
import Form from "../components/form"
import Seo from "../components/seo"
import { graphql } from "gatsby"

export const query = graphql`
    {
        posts: allContentfulBlogPost(sort: {order: DESC, fields: publishDate}) {
            nodes {
                id
                title
                publishDate(formatString: "DD MMM YYYY")
                externalUrl
                description {
                    description
                }
                slug
            }
        }
    }
`;

function Blog({ data }) {
    return (
        <Layout>
            <Seo
                title="Our Blog"
                description="Opinion articles, tips and advice for anyone suffering body image issues."
            />

            <Page pageTitle="Blog" pageClass="page-blog">
            {data.posts.nodes.map(post => (
                <article className={`post-listing ${post.externalUrl ? 'post-external' : ''}`} key={`post-${post.slug}`}>
                    <time className="post-list-date">
                        <span>{post.publishDate}</span>
                    </time>
                    <h3>{post.title}</h3>
                    <div>{post.description.description}</div>
                    <p><a href={post.externalUrl ? post.externalUrl : `/blog/${post.slug}`} target={post.externalUrl ? '_blank' : ''} rel={post.externalUrl ? 'noreferrer noopener' : ''} className="post-list-lnk">Read more &rarr;</a></p>
                </article>
            ))}
            </Page>

            <Form />
        </Layout>
    );
}

export default Blog
